import Document from '~/editor/document/document'
import { Point, TPointerEvent, TPointerEventInfo } from 'fabric'
import { MOUSE } from '~/editor/mouse-handler/codes'

class MouseHandler{
  document: Document
  pointerStart = new Point(0, 0)
  pointerOffset = new Point(0, 0)
  pointerEnd = new Point(0, 0)
  pointerDown = false
  onSelectionBox?: (mouseHandler: MouseHandler) => void

  constructor(document: Document){
    this.document = document
    this.registerEventListeners()
  }

  onMouseDown(event: TPointerEventInfo<TPointerEvent>){
    if('button' in event.e){
      if(this.document.panToggle) this.document.isPanning = true
      this.pointerStart.setXY(event.viewportPoint.x, event.viewportPoint.y)
    }
  }
  
  onMouseMove(event: TPointerEventInfo<TPointerEvent>){
    if(this.document.isPanning && 'button' in event.e){
      this.pointerOffset.setXY(
        event.viewportPoint.x - this.pointerStart.x,
        event.viewportPoint.y - this.pointerStart.y
      )

      if(event.e.button === MOUSE.LEFT){
        this.document.transform.offset.setFromPoint(this.pointerOffset)
        this.document.render()
      }
    }
  }

  onMouseUp(event: TPointerEventInfo<TPointerEvent>) {
    this.pointerDown = false
    if('button' in event.e){
      this.pointerEnd.setXY(event.viewportPoint.x, event.viewportPoint.y)

      // End of panning
      if (this.document.isPanning){
        this.document.isPanning = false
        this.document.transform.pan.setFromPoint(this.document.transform.pan.add(this.pointerOffset))
        this.document.transform.offset.setXY(0, 0)
        this.document.render()
      // End of Left click on image triggers selection box
      } else if (event.target?.isType("Image") && this.onSelectionBox) {
        this.onSelectionBox(this)
      }
    }
  }

  registerEventListeners(){
    this.document.canvas.on('mouse:up', event => this.onMouseUp(event))
    this.document.canvas.on('mouse:move', event => this.onMouseMove(event))
    this.document.canvas.on('mouse:down', event => this.onMouseDown(event))
  }
}

export default MouseHandler