import { appendTranslation } from '~/services/current-document/translations'
import { dbRowToTranslationVersion } from '~/helpers/database-conversions'
import Translation from '~/editor/translation/translation'
import { reportError } from '~/services/errors'
import { LoadTranslationsProps } from '~/services/current-document/loading/translations.interfaces'
import { setLoadingState } from '~/services/loading/loading'

const loadTranslations = async ({ chapterData, translationsData, commentsData, currentDocument, setIsLoading }: LoadTranslationsProps) => {
  currentDocument.loadPages(chapterData.pages.original).then(() => {
    // Reduce to unique translation uuids
    const uniqueIds = translationsData.reduce<string[]>((result, entry) => {
      if (!result.includes(entry.entry_id)) {
        result = result.concat(entry.entry_id)
      }
      return result
    }, [])

    uniqueIds.forEach(id => {
      // Group versions using their parent translation uuid
      const versions = translationsData
        .filter(entry => entry.entry_id === id)
        .map(entry => dbRowToTranslationVersion(entry))
        .sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()))

      // Group comments using their parent translation uuid
      const comments = commentsData
        .filter(entry => entry.entry_id === id)

      // Get the page where the translation is
      const page = currentDocument.pages.find(page => page.id === versions[0].pageId)

      // Create a new translations with its versions & comments
      if (page) {
        appendTranslation(new Translation({
          id,
          versions,
          page,
          comments
        }))
      }
    })
  }).catch(error => {
    reportError(error)
  }).finally(() => {
    setLoadingState({
      loading: false
    })
    setIsLoading(false)
  })
}

export {
  loadTranslations
}