import styles from '~/components/canvas-view/canvas-view.module.scss'
import { Component, onMount } from 'solid-js'
import { CanvasViewProps } from '~/components/canvas-view/canvas-view.interfaces'
import RowButton from '~/components/row-button/row-button'
import ZoomInIcon from '~/assets/icons/zoom-in.svg'
import ZoomOutIcon from '~/assets/icons/zoom-out.svg'
import ZoomResetIcon from '~/assets/icons/minimize.svg'
import PrevPageIcon from '~/assets/icons/chevron-up.svg'
import NextPageIcon from '~/assets/icons/chevron-down.svg'
import { createSignal } from 'solid-js'
import Document from '~/editor/document/document'

const CanvasView: Component<CanvasViewProps> = ({ settings, onReady, invisible }) => {
  let canvasViewport: HTMLDivElement | undefined
  let canvasElement: HTMLCanvasElement | undefined
  let scrollableArea: HTMLDivElement | undefined

  const [document, setDocument] = createSignal<Document>()

  const cssStyles = {
    [styles.canvasViewport]: true,
    [styles.invisible]: invisible
  }

  onMount(() => {
    if (canvasViewport && canvasElement && scrollableArea){
      onReady({ canvasElement, canvasViewport, scrollableArea, settings }).then(document => {
        setDocument(document)
      })
    }
  })

  const onZoomIn = (event: MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur()
    document()?.zoomIn()
  }

  const onZoomOut = (event: MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur()
    document()?.zoomOut()
  }

  const onReset = (event: MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur()
    document()?.resetZoom()
  }

  const onPrevPage = (event: MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur()
    document()?.prevPage()
  }
  
  const onNextPage = (event: MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur()
    document()?.nextPage()
  }

  return (
    <div classList={cssStyles} ref={canvasViewport}>
      {!settings.hideControls && (
        <aside class={styles.controls}>
          <RowButton
            onClick={onZoomIn}
            icon={ZoomInIcon}
          />
          <RowButton
            onClick={onZoomOut}
            icon={ZoomOutIcon}
          />
          <RowButton
            onClick={onReset}
            icon={ZoomResetIcon}
          />
          <RowButton
            onClick={onPrevPage}
            icon={PrevPageIcon}
          />
          <RowButton
            onClick={onNextPage}
            icon={NextPageIcon}
          />
        </aside>
      )}
      <div class={styles.scrollableArea} ref={scrollableArea} />
      <div class={styles.canvas}>
        <canvas ref={canvasElement} />
      </div>
    </div>
  )
}

export default CanvasView