import { Point } from 'fabric'
import { v4 as uuidv4 } from 'uuid'
import { CreateTranslationProps } from '~/editor/translation/create.interfaces'
import Translation from '~/editor/translation/translation'
import { TranslationVersion } from '~/editor/translation/translation.interfaces'
import { createTranslation } from '~/services/current-document/translations'
import { user } from '~/services/database/users'

export default function createFromCoords({
  pointerStart,
  pointerEnd,
  page,
  transformMatrix,
}: CreateTranslationProps) {
  const id = uuidv4()
  
  // Isolate the parts of the transformMatrix
  const canvasTranslation = new Point(
    transformMatrix[4],
    transformMatrix[5]
  )
  const canvasZoom = page.document.canvas.getZoom()

  // Project the pointer coordinates on the page
  // taking into account the canvas translation and zoom
  let startPoint = new Point(
    (pointerStart.x - canvasTranslation.x) / (page.image.getScaledWidth() * canvasZoom),
    (pointerStart.y - canvasTranslation.y - (page.image.top * canvasZoom)) / (page.image.getScaledHeight() * canvasZoom)
  )
  let endPoint = new Point(
    (pointerEnd.x - canvasTranslation.x) / (page.image.getScaledWidth() * canvasZoom),
    (pointerEnd.y - canvasTranslation.y - (page.image.top * canvasZoom)) / (page.image.getScaledHeight() * canvasZoom)
  )

  // If selection is too small, create a default box around startPoint
  const minSelectionSize = 0.05 // 5% of page
  const defaultBoxSize = 0.15 // 15% of page
  const isSelectionTooSmall =
    Math.abs(startPoint.x - endPoint.x) < minSelectionSize

  if (isSelectionTooSmall) {
    const boxH =
      (defaultBoxSize * page.image.getScaledWidth()) /
      page.image.getScaledHeight()
    startPoint = new Point(
      startPoint.x - defaultBoxSize / 2,
      startPoint.y - boxH / 2
    )
    endPoint = new Point(
      endPoint.x + defaultBoxSize / 2,
      endPoint.y + boxH / 2
    )
  }

  const initialVersion: TranslationVersion = {
    id,
    startPoint,
    endPoint,
    date: new Date().toUTCString(),
    translatedText: '',
    originalText: '',
    userId: user()?.id ?? null,
    pageId: page.id,
  }

  const translation = new Translation({
    id,
    versions: [initialVersion],
    page: page,
  })

  createTranslation(translation, { canvas: true, database: true, view: true, history: true })
}