import { DocumentProps } from '~/editor/document/document.interfaces'
import { imageLoader } from '~/editor/image/image'
import Page from '~/editor/page/page'
import { Page as PageData } from '~/types/comic/page'
import { ActiveSelection, Canvas, FabricObject, Point, TPointerEventInfo, util } from 'fabric'
import { resetTranslations, scaleTranslations } from '~/services/current-document/translations'
import { history } from '~/editor/history'
import { scaleTypesetTexts } from '~/services/current-document/typeset-texts'
import { Job } from '~/types/comic/chapter'
import MouseHandler from '~/editor/mouse-handler/mouse-handler'
import createFromCoords from '~/editor/translation/create'

class Document extends EventTarget{
  canvas: Canvas
  canvasViewport: HTMLDivElement
  scrollableArea: HTMLDivElement
  width: number
  pages: Page[]
  hasFocus: boolean
  documentHeight: number
  settings: {
    mode: Job
    readOnly: boolean
  }
  selection: ActiveSelection | null
  linkedDocument?: Document
  transformMatrix = util.composeMatrix({})
  transform = {
    zoom: localStorage.getItem('zoom') ? parseFloat(localStorage.getItem('zoom')!) : 1,
    pan: new Point(0, 0),
    offset: new Point(0, 0)
  }
  panToggle = false
  isPanning = false

  constructor({ canvasElement, canvasViewport, scrollableArea, settings }: DocumentProps){
    super()
    this.canvas = new Canvas(canvasElement, {
      selection: !settings.readOnly
    })
    this.canvasViewport = canvasViewport
    this.scrollableArea = scrollableArea
    this.canvas.imageSmoothingEnabled = true
    this.width = this.canvasViewport.offsetWidth
    this.documentHeight = 0
    this.settings = {
      mode: settings.mode,
      readOnly: settings.readOnly !== undefined ? settings.readOnly : false
    }
    this.selection = null
    this.pages = []
    this.hasFocus = !this.settings.readOnly
    history.clear()
    
    this.registerEventListeners()
  }

  async loadPages(pages: PageData[]){
    this.reset()
    const imageLoaders: Promise<HTMLImageElement>[] = []
    pages.forEach(page => {
      imageLoaders.push(imageLoader(page.url))
    })

    await Promise.all(imageLoaders).then(images => {
      // Create Fabric Images for each page
      images.forEach((image, index) => {
        const pageId = pages.find(entry => entry.url === image.src)?.id
        if(pageId){
          const page = new Page({
            document: this,
            image,
            id: pageId,
            index
          })
          this.pages.push(page)
          this.canvas.add(page.image)
          this.canvas.add(page.pageIndex)
          this.canvas.sendObjectToBack(page.image)
        }
      })

      // Scale at current zoom
      this.onCanvasResize()
      this.dispatchEvent(new Event('pagesready'))
    })
  }

  add(element: FabricObject | FabricObject[]) {
    const objects = Array.isArray(element) ? element : [element]
    this.canvas.add(...objects)
  }

  remove(element: FabricObject | FabricObject[]){
    const objects = Array.isArray(element) ? element : [element]
    this.canvas.remove(...objects)
  }

  reset(){
    this.pages = []
    if(this.settings.mode === 'translation' || this.settings.mode === 'proofreading') resetTranslations()
    this.canvas.clear()
  }

  scalePages(){
    this.documentHeight = 0

    this.pages.forEach(page => {
      // Update the scale according to canvas size & zoom
      // Offset each page after the last one
      page.resize()
      // Compute document height by adding all pages height
      this.documentHeight += page.image.getScaledHeight()
    })

    this.scrollableArea.style.height = `${this.documentHeight}px`

    scaleTranslations()
    scaleTypesetTexts()
    this.onScroll()
  }

  onSelection(){
    const activeObject = this.canvas.getActiveObject()
    if (activeObject?.isType('activeselection')){
      this.selection = activeObject as ActiveSelection
      this.selection.lockRotation = true
      this.selection.setControlVisible('mtr', false)
    }else{
      this.selection = null
    }
  }

  onSelectionClear(){
    this.selection = null
  }

  onObjectMove(){
    const selection = this.canvas.getActiveObjects()
    if (selection.length > 1){
      selection.forEach(object => {
        object.fire('moving')
      })
    }
  }

  onObjectScale(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('scaling')
      })
    }
  }

  onObjectRotate(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('rotating')
      })
    }
  }

  onObjectMouseUp(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('mouseup')
      })
    }
  }

  onKeyDown(event: KeyboardEvent){
    const chordKey = window.navigator.platform.includes('Mac') ? event.metaKey : event.ctrlKey

    // Action Keys
    if(!this.settings.readOnly && this.hasFocus){
      // Delete
      if(event.code === "Delete" || event.code === "Backspace"){
        this.remove(this.canvas.getActiveObjects())
      }
      // Undo/Redo
      if(event.code === "KeyW" && chordKey && !event.shiftKey){
        event.preventDefault()
        history.undo()
      }
      if((event.code === "KeyY" && chordKey) || (event.code === "KeyW" && chordKey && event.shiftKey)){
        event.preventDefault()
        history.redo()
      }
    }

    // Transform Keys (can work in read-only mode)
    if(this.hasFocus){
      if(event.code === "Space" && !chordKey){
        event.preventDefault()
        this.panToggle = true
        this.canvas.selection = false
        this.canvas.setCursor("grab")
      }
    }
  }

  onKeyUp(event: KeyboardEvent){
    event.preventDefault()
    this.panToggle = false
    this.canvas.selection = true
    this.canvas.setCursor("crosshair")
  }

  getCurrentPage(){
    let currentPage = this.pages[0]
    this.pages.forEach(page => {
      if(page.image.top <= this.canvasViewport.scrollTop && page.image.top + page.image.getScaledHeight() >= this.canvasViewport.scrollTop){
        currentPage = page
      }
    })
    return currentPage
  }

  onMouseWheel(event: TPointerEventInfo<WheelEvent>){
    if(this.linkedDocument){
      const currentPage = this.getCurrentPage()
      const linkedCurrentPage = this.linkedDocument?.pages[currentPage.index]
      const currentPageBottom = this.canvasViewport.scrollTop + currentPage.image.top + currentPage.image.getScaledHeight()
      const linkedCurrentPageBottom = this.linkedDocument.canvasViewport.scrollTop + linkedCurrentPage.image.top + linkedCurrentPage.image.getScaledHeight()
      const pageDifferInHeight = currentPage.image.getScaledHeight() !== linkedCurrentPage.image.getScaledHeight()
      const pageDifference = (currentPage.image.top + currentPage.image.getScaledHeight()) - (linkedCurrentPage.image.top + linkedCurrentPage.image.getScaledHeight())
      const currentPageDifference = currentPageBottom - linkedCurrentPageBottom
      const isAsync = currentPageDifference !== 0
      const whoIsLate = currentPageDifference > 0 ? this : this.linkedDocument

      // Remaining amount to cover
      const offset = pageDifference*2 - currentPageDifference
      const shouldCatchup = event.e.deltaY > 0 ? offset < 0 : offset > pageDifference

      if(isAsync && pageDifferInHeight && shouldCatchup){
        whoIsLate.canvasViewport.scrollBy({ top: event.e.deltaY })
      }else{
        const asyncPageCaughtUpForward = pageDifferInHeight && offset > 0
        const asyncPageCaughtUpBackward = pageDifferInHeight && offset < 0

        if(asyncPageCaughtUpForward || asyncPageCaughtUpBackward){
          // Resync pages by adding their offset 
          // for each page that has caught up
          const upToPageIndex = asyncPageCaughtUpBackward ? currentPage.index : currentPage.index+1
          let documentOffset = 0
          let linkedDocumentOffset = 0

          for(let i = 0; i < upToPageIndex; i++){
            const page = this.pages[i]
            const linkedPage = this.linkedDocument.pages[i]
            const pageDifference = page.image.getScaledHeight() - linkedPage.image.getScaledHeight()
            if(pageDifference !== 0){
              if(pageDifference < 0){
                linkedDocumentOffset += Math.abs(pageDifference)
              }else{
                documentOffset += Math.abs(pageDifference)
              }
            }
          }

          // Align both views 
          this.canvasViewport.scrollTo({ top: this.canvasViewport.scrollTop + documentOffset })
          if(this.linkedDocument){
            this.linkedDocument.canvasViewport.scrollTo({ top: this.canvasViewport.scrollTop + linkedDocumentOffset })
          }
        }

        this.canvasViewport.scrollBy({ top: event.e.deltaY })
        if(this.linkedDocument){
          this.linkedDocument.canvasViewport.scrollBy({ top: event.e.deltaY })
        }
      }
    }else{
      this.canvasViewport.scrollBy({ top: event.e.deltaY })
    }
  }

  onScroll(){
    this.render()
  }

  render(){
    this.transformMatrix = util.composeMatrix({
      scaleX: this.transform.zoom,
      scaleY: this.transform.zoom,
      translateY: -1 * this.canvasViewport.scrollTop + this.transform.pan.y + this.transform.offset.y,
      translateX: (this.canvas.getWidth() - this.canvas.getWidth() * this.transform.zoom) / 2 + this.transform.pan.x + this.transform.offset.x
    })
    this.canvas.setViewportTransform(this.transformMatrix)
    this.canvas.renderAll()

    if(this.linkedDocument){
      this.linkedDocument.transform = this.transform
      this.linkedDocument.transformMatrix = util.composeMatrix({
        scaleX: this.transform.zoom,
        scaleY: this.transform.zoom,
        translateY: -1 * this.linkedDocument.canvasViewport.scrollTop + this.transform.pan.y + this.transform.offset.y,
        translateX: (this.canvas.getWidth() - this.canvas.getWidth() * this.transform.zoom) / 2 + this.transform.pan.x + this.transform.offset.x
      })
      this.linkedDocument.canvas.setViewportTransform(this.linkedDocument.transformMatrix)
      this.linkedDocument.canvas.renderAll()
    }
  }

  onCanvasResize(){
    this.canvas.discardActiveObject()
    this.canvas.setDimensions({
      height: this.canvasViewport.offsetHeight,
      width: this.scrollableArea.offsetWidth
    })
    this.width = this.canvasViewport.offsetWidth
    this.scalePages()
    this.setZoom(this.transform.zoom)
  }

  scrollTo(top: number){
    this.canvasViewport.scrollTo({ top: top * this.transform.zoom })
    if(this.linkedDocument){
      this.linkedDocument.canvasViewport.scrollTo({ top: this.canvasViewport.scrollTop })
    }
  }

  setZoom(zoom: number){
    const zoomOrigin = new Point(
      this.canvas.getWidth() / 2,
      0
    )

    // Apply new zoom to canvas
    this.canvas.zoomToPoint(zoomOrigin, zoom)
    this.transform.zoom = zoom

    // Apply new zoom to linked document
    if (this.linkedDocument) {
      this.linkedDocument.canvas.zoomToPoint(zoomOrigin, zoom)
      this.linkedDocument.transform.zoom = zoom
    }

    // Store new zoom in local storage
    localStorage.setItem('zoom', zoom.toString())

    // Save scroll position in percentage
    const scrollPosition = this.canvasViewport.scrollTop / parseFloat(this.scrollableArea.style.height)

    // Resize scrollable viewport
    this.scrollableArea.style.height = `${this.documentHeight * zoom}px`

    // Scroll to the same position in the new zoom
    this.scrollTo(scrollPosition * this.documentHeight)

    // Render the document to compute the new transformMatrix with the new zoom
    this.render()
  }

  zoomIn(){
    this.setZoom(this.canvas.getZoom() * 1.1)
  }

  zoomOut() {
    this.setZoom(this.canvas.getZoom() * 0.9)
  }

  resetZoom(){
    this.transform.pan.setXY(0,0)
    this.setZoom(1)
  }

  // We apply Math.floor to both values to avoid floating point errors
  prevPage() {
    const zoom = this.transform.zoom
    const scrollTop = this.canvasViewport.scrollTop
    const page = this.pages.findLast(page => 
      Math.floor(page.image.top * zoom) < Math.floor(scrollTop)
    )
    if (page) {
      this.scrollTo(page.image.top)
    }
  }

  nextPage() {
    const zoom = this.transform.zoom
    const scrollTop = this.canvasViewport.scrollTop
    const page = this.pages.find(page => 
      Math.floor(page.image.top * zoom) > Math.floor(scrollTop)
    )
    if (page) {
      this.scrollTo(page.image.top)
    }
  }

  onSelectionBox(handler: MouseHandler) {
    const scrollTop = this.canvasViewport.scrollTop
    const page = this.pages.find(page => {
      const pageBottomY = - scrollTop + page.image.top + page.image.getScaledHeight()
      return pageBottomY > handler.pointerStart.y
    })
    if (page) {
      createFromCoords({
        pointerStart: handler.pointerStart,
        pointerEnd: handler.pointerEnd,
        page,
        transformMatrix: this.canvas.viewportTransform
      })
    }
  }

  registerEventListeners(){
    const mouseHandler = new MouseHandler(this)
    if (!this.settings.readOnly && this.settings.mode === 'translation'){
      mouseHandler.onSelectionBox = (handler) => this.onSelectionBox(handler)
    }
    this.canvas.on('mouse:wheel', (event) => this.onMouseWheel(event))
    this.canvas.on('mouse:up', () => this.onObjectMouseUp())
    this.canvas.on('selection:created', () => this.onSelection())
    this.canvas.on('selection:updated', () => this.onSelection())
    this.canvas.on('selection:cleared', () => this.onSelectionClear())
    this.canvas.on('object:moving', () => this.onObjectMove())
    this.canvas.on('object:scaling', () => this.onObjectScale())
    this.canvas.on('object:rotating', () => this.onObjectRotate())
    this.canvasViewport.addEventListener('scroll', () => this.onScroll())
    window.addEventListener('resize', () => this.onCanvasResize())
    window.addEventListener('keydown', (event) => this.onKeyDown(event))
    window.addEventListener('keyup', (event) => this.onKeyUp(event))
  }
}

export default Document